function isUUIDv4(uuid: string) {
  const pattern = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/
  return pattern.test(uuid)
}

export function getCarerIndex(id: string) {
  if (!isUUIDv4(id)) {
    throw new Error('Invalid UUID')
  }
  const clinicStore = useClinicStore()
  return clinicStore.staff?.findIndex((x) => x.id === id)
}

export function getCarer(id: string) {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)
  return clinicStore.staff[carerIndex]
}

export function getCarerName(id: string) {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)
  return `${clinicStore.staff[carerIndex].first_name} ${clinicStore.staff[carerIndex].last_name}`
}

export function getCarerProfilePicture(id: string) {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)
  return clinicStore.staff[carerIndex].profile_picture
}

export function getCarerColor(id: string) {
  const clinicStore = useClinicStore()
  const carerIndex = getCarerIndex(id)
  return clinicStore.staff[carerIndex].color
}

export function calculateCarerColor(carerColor: string) {
  if (!carerColor) return '#fff'

  return Number.parseInt(carerColor.replace('#', ''), 16) > 0xffffff / 2
    ? '#000'
    : '#fff'
}
